/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    left: 15px;
    bottom: 15px;
    z-index: 996;
    background: var(--oksijen-turuncu);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
    border: none;
    &:hover {
        background: rgba(148, 90, 42, 1);
        color: rgba(255, 255, 255, 1);
    }
    &.active {
        visibility: visible;
        opacity: 1;
    }
    i {
        font-size: 28px;
        color: rgba(255, 255, 255, 1);
        line-height: 0;
    }
}
